import React from 'react';
import './Box.css';
import Results from '../Results/Results.js';

class Box extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
			ipAddress: 'unknown',
			hostName: 'unknown',
			domainName: 'unknown',
			nameServers: [],
			mailServers: [],
			webHostingByOnOffice: false,
			webHostingInfo: 'unknown',
			domainHostingByOnOffice: false,
			mxByOnOffice: false,
			showResult: false,
			error: false,
			errorMessage: ''
        };
    }

    handleChange = () => {
        this.setState({
			ipAddress: 'unknown',
			hostName: 'unknown',
			domainName: 'unknown',
			nameServers: [],
			mailServers: [],
			webHostingByOnOffice: false,
			webHostingInfo: 'unknown',
			domainHostingByOnOffice: false,
			mxByOnOffice: false,
			showResult: false,
			error: false,
			errorMessage: ''
        })

		if (!this.state.error) {

			let resElement = document.getElementById('result');
			let resDetailElement = document.getElementById('result-detail');
		

			resElement.style.display = 'none';
			resDetailElement.style.display = 'none';
		}
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        this.getData()
    }

    async getData() {
		let api = 'https://tools.onofficeweb.com/dns-checker/?hostname=';
        let myUrl = document.getElementById('url_to_check').value;

		// remove http:// or https:// and sub-directories from URL
        myUrl = myUrl.replace(/^(?:https?:\/\/)?/i, "").split('/')[0];

        // GET request using fetch with async/await
        await fetch(api + myUrl)
			.then(response => response.json())
			.then(data => {
				if (!data.error) {
					this.setState({
						ipAddress: data.ipAddress,
						hostName: data.hostName,
						domainName: data.domainName,
						nameServers: data.nameServers,
						mailServers: data.mailServers,
						webHostingByOnOffice: data.webHostingByOnoffice,
						webHostingInfo: data.webHostingInfo,
						domainHostingByOnOffice: data.domainHostingByOnoffice,
						mxByOnOffice: data.mxByOnoffice,
						spfRecord: data.spfRecord,
						showResult: true
					})
				}
				else {
					this.setState({
						error: true,
						errorMessage: 'The domain you entered could not be found.'
					})
				}
			})
			.catch(err => {
				console.error("Domain not found: " + err);
				this.setState({
					error: true,
					errorMessage: 'The domain you entered could not be found.'
				})
			  });
    }

    render() {
        return(
            <div className="box-outer">
                <div className="box-inner">
                    <div className="box-title-wrapper">
                        <span className="box-title">Is this website hosted by onOffice?</span>
                    </div>
                    <div className="ip-form-wrapper">
                        <form className="ip-form" id="ip-form" onSubmit={ this.handleSubmit }>
                            <label htmlFor="url_to_check">Please enter URL</label>
                            <input type="text" name="url_to_check" id="url_to_check" placeholder="Please enter URL" onChange={ this.handleChange }/>
                            
                            <input form="ip-form" type="submit" id="submit" name="submit" value="Check" />
                        </form>
                    </div>
					{ this.state.error ? <h1>{this.state.errorMessage}</h1> : <Results resultData = { this.state } /> }
                </div>
            </div>
        )
    }
}

export default Box;