import React from 'react';
import logo from '../img/onOffice_Logo2024_white.png';
import Box from '../Box/Box';
import '../App/App.css';

function App() {
	return (
		<div className="App">
			<header className="header">
				<img src={logo} className="header-logo" alt="logo" />
			</header>
            <Box />
		</div>
	);
}

export default App;