import React from 'react';
import './Results.css';

const Results = ({ resultData }) => {
	let resultWebHosting;
	let resultDomainHosting;
	let resultMxByOnOffice;
	let resultSpfRecord;
	let resElement = document.getElementById('result');
	let resDetailElement = document.getElementById('result-detail');

	const nameServerItems = resultData.nameServers.map(function(nameServer, i){
		return <li key={i}>Nameserver {i + 1}: <span className="result-val result-nameserver">{nameServer}</span></li>
	});

	const mailServerItems = resultData.mailServers.map(function(mailServer, j){
		return <li key={j}>Mailserver {j + 1}: <span className="result-val result-mailserver">{mailServer}</span></li>
	});

	if (resultData.showResult) {
		if (resultData.webHostingByOnOffice) {
			resultWebHosting = <span className="success">onOffice Website</span>;
		}
		else {
			resultWebHosting = <span className="error">Not an onOffice website</span>;
		}

		if (resultData.domainHostingByOnOffice) {
			resultDomainHosting = <span className="success">with onOffice</span>;
		}
		else {
			resultDomainHosting = <span className="error">not with onOffice</span>;
		}

		if (resultData.mxByOnOffice) {
			resultMxByOnOffice = <span className="success">with onOffice</span>;
		}
		else {
			resultMxByOnOffice = <span className="error">not with onOffice</span>;
		}

		if (resultData.spfRecord) {
			resultSpfRecord = <span>{resultData.spfRecord}</span>
		}
		else {
			resultSpfRecord = <span className="error">no entry</span>;
		}

		resElement.style.display = 'block';
		resDetailElement.style.display = 'block';

	}
	return (
	<div className="result-wrapper">
		<div className="result" id="result" hosted={resultData.webHostingByOnOffice ? 1: undefined}>
			{resultWebHosting}
		</div>
		<div className="result-detail" id="result-detail">
			<ul>
				<li>IP address: <span className="result-val result-ip">{resultData.ipAddress}</span></li>
				<li>Hostname: <span className="result-val result-url">{resultData.hostName}</span></li>
				<li>Domain-Name: <span className="result-val result-url">{resultData.domainName}</span></li>
				<li>Web-Hosting: <span className="result-val result-webhosting">{resultWebHosting}</span></li>
				{resultData.webHostingByOnOffice ? <li>onOffice Hosting-Info: <span className="result-val result-webhosting">{resultData.webHostingInfo}</span></li> : ''}
				<li>Domain-Hosting: <span className="result-val result-domainhosting">Domain {resultDomainHosting}</span></li>
				{resultData.error ? <p>FEHLER</p> : nameServerItems}
				<li>Mailserver: <span className="result-val result-webhosting">Mailserver {resultMxByOnOffice}</span></li>
				{resultData.error ? <p>FEHLER</p> : mailServerItems}
				<li>SPF Record: <span className="result-val result-spf">{resultSpfRecord}</span></li>
			</ul>
		</div>
	</div>
	)
}

export default Results;